import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"


export default function WhoTemplate() {
  return (
    <Layout breadcrumbs={["Our Members"]}>
    <div className="carousel-container">
      <div className="group-5-about-us">
      <div className="connecting-the-blockchain-together">Our Members</div>
      <p className="about-us-carousel">We are proud to be a nonprofit organization, funded entirely by our members, sponsors, and partners. It’s simple: we all chip in because we all benefit.<br /><br />In addition to our individual, corporate, and student members, these are the companies and organizations that make our good work possible.</p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/w_2880/v1705702247/our-members/bba-our-members-header_gcfaox.jpg" className="group-3" />
    </div>  
	<div className="our-members-container">
	<div className="our-members-title">Sponsors</div>
	<div className="our-members-subtitle">Over 3,500 participants from the world’s leading companies:</div>
	<div className="partner-sponsor-carousel">
		<a href="https://ripple.com/" target="_blank"><img className="our-members-sponsors"  src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743803/branding/new%20logos/Ripple_logo.svg_ocj9ap.png" /></a>
		<a href="https://www2.deloitte.com/us/en.html" target="_blank"><img className="our-members-sponsors"  src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743736/branding/new%20logos/deloitte_qb0dcy.png" /></a>
	</div>
	<div className="partner-sponsor-carousel">	
	 	<a href="https://algorandtechnologies.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743717/branding/new%20logos/algorand-foudation-logo_vii5eg.png" /></a>
	</div>
	<div className="partner-sponsor-carousel">
			<a href="https://www.ropesgray.com/en" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743710/branding/new%20logos/ropes-and-gray_aalhol.png" /></a>	
		<a href="https://mediashower.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743787/branding/new%20logos/mediashower_bqbswj.png" /></a>	
</div>
<div className="partner-sponsor-carousel">
		<a href="https://toposware.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743713/branding/new%20logos/toposware_ufwiux.svg" /></a>	
		<a href="https://copper.co/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743729/branding/new%20logos/copper_mwbkr2.png" /></a>
		<a href="https://provenance.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743799/branding/new%20logos/provenance-blockchain_wgkqxm.png" /></a>
		<a href="https://arival.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743725/branding/new%20logos/arival_gtwklz.png" /></a>
		<a href="https://true-tickets.com/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704914672/branding/new%20logos/True-Tickets_logo_m3sn6i.png" /></a>
		<a href="https://www.fyeo.io/" target="_blank"><img className="our-members-sponsors" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1706107169/our-members/Logo-black-06_uy1y9j.svg" /></a>
</div>
	<div className="our-members-title">Community Partners</div>
		<div className="partner-sponsor-carousel">
				<a href="https://www.fcatalyst.com/overview" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743750/branding/new%20logos/fcat_zbvxpf.png" /></a>
				<a href="https://www.neach.org/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743791/branding/new%20logos/neach_jb7tfm.png" /></a>
			    <a href="https://masstech.org/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1704743781/branding/new%20logos/masstech_ncj4rs.png" /></a>
				<a href="https://www.bostonfed.org/" target="_blank"><img className="our-members-partners" src="https://res.cloudinary.com/dfwexsly7/image/upload/v1706643673/our-members/federal-reserve_rxfwvt_d8ljdy.png" /></a>
		</div>
	</div>
	    <div className="container-noflex">
        <div className="our-members-cta-new">
          <div className="our-members-content-new">
            <h2>Become a Member</h2>
            <p>All BBA members get access to exclusive in-person events, online workshops, and professional development opportunities.<br /><br />Corporate members get access to our community, our talent pipeline, and our enterprise innovation hub.</p>
            <a href="/become-a-member"><button className="who-we-are-new-volunteer-button">JOIN THE BBA</button></a>
          </div>
          <div className="our-members-img">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1705621199/our-members/bba-our-members-bottom_yynraw.jpg" />
          </div>          
         </div>  
    </div>  
      </Layout>
  )
}